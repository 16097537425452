@tailwind base;
@tailwind components;
@tailwind utilities;

  
  .paragraphs{
    @apply text-gray-600 text-justify mb-6 font-sans
  }

  .heading2{
    @apply text-2xl font-bold mt-8 mb-4
  }

  .my-ul{
    @apply list-inside list-image-checkmark items-center text-gray-600 mb-3 font-sans space-y-3
  }

  .heading3{
    @apply text-lg font-semibold mt-4 mb-2 
  }

  .service-style{
    @apply bg-white py-6 px-4 md:px-8 lg:px-16
  }
  .service-div{
    @apply max-w-7xl mx-auto flex flex-col lg:flex-row
  }



  .banner-style{
    @apply p-3 bg-back-img justify-center items-center
  }

  .banner-heading{
    @apply text-center text-3xl font-semibold font-sans mb-3
  }

  .banner-div{
    @apply flex justify-center items-center gap-2
  }

  .banner-link{
    @apply text-black text-base transition hover:text-blue-500
  }

  .banner-icon{
    @apply text-lg font-bold text-amber-500
  }

  .banner-p{
    @apply text-gray-500 text-base
  }

  .footer-link{
    @apply flex  items-center gap-2 text-white transition hover:text-amber-500
  }
